.worker-aspect-card {
    border-radius: 1em;
    border-color: var(--bs-gray-300) !important;
}

.worker-aspect-card.worker-aspect-card .line-graph-container{
    position: relative;
}

.worker-aspect-card.worker-aspect-card.lg-card .line-graph-container{
    height: 70px;
    width: 80px;
}

.worker-aspect-card.worker-aspect-card.md-card .line-graph-container{
    height: 50px;
    width: 50px;
}

.worker-aspect-card .card-title{
    color: var(--bs-gray-600);
}

.worker-aspect-card .card-content{
    font-weight: normal;
}

.worker-aspect-card.md-card .card-title{
    font-size: 0.7em;
}

.worker-aspect-card.md-card .card-content{
    font-size: 1.5em;
}

.worker-aspect-card.lg-card .card-title{
    font-size: 1em;
}

.worker-aspect-card.lg-card .card-content{
    font-size: 2em;
}