.task-details-container .task-network-graph-wrapper,
.task-details-container .task-related-workers-wrapper{
    height: 625px;
}

.task-details-container .task-network-graph-wrapper #task-network-graph{
    width: 100% !important;
    height: 580px;
}

.task-details-container .table{
    font-size: 12px;
}
