:root{
  --custom-dim-opacity: 0.5;
  --custom-green: #769631;
  --custom-success :  rgba(var(--bs-success-rgb), var(--custom-dim-opacity));
  --custom-danger :  rgba(var(--bs-danger-rgb), var(--custom-dim-opacity));
  --custom-warning :  rgba(var(--bs-warning-rgb), var(--custom-dim-opacity));
}

/*the style arguments for the sidebar. We use position:fixed and a fixed width*/
.nav-side-bar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16rem;
  padding: 2rem 1rem;
  background-color: #f8f9fa;
  z-index: 11;
}

/* the styles for the main content position it to the right of the sidebar and add some padding.*/
.content {
  margin-left: 18rem;
  margin-right: 2rem;
  padding: 2rem 1rem;
  z-index: 10;
}

.capitalize-first-letter::first-letter {
  text-transform: capitalize;
}

.table th {
  font-weight: normal;
}

.table.left-border-table tr,
.table.left-circle-table tr{
  position:relative;
}

.table.left-border-table tr td:first-child::before{
  content: "";
  position: absolute;
  width: 0.2em;
  height: 1.3em;
  left: -0em;
  top: 30%;
  right: 0;
  bottom: 0px;
}

.table.left-circle-table tr td:first-child::before{
  content: "";
  position: absolute;
  width: 0.5em;
  height: 0.5em;
  border-radius: 100%;
  left: -0.2em;
  top: 40%;
  right: 0;
  bottom: 0px;
}

.table tr.success-left-border td:first-child::before{
  background-color: var(--custom-success);
}


.table tr.danger-left-border td:first-child::before {
  background-color: var(--custom-danger);
}

.table tr.warning-left-border td:first-child::before {
  background-color: var(--custom-warning);
}

.table th.sortable{
  text-decoration: underline;
  cursor: pointer;
}

h2+hr{
  border-top: 2px solid;
}