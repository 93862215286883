
.dashboard .container-fluid > .row:not(:first-child) {
    margin-top: 30px;
}

.dashboard .container-fluid .row .row:not(:first-child) {
    margin-top: 20px;
}

.dashboard .table th,
.dashboard .table td{
    font-size: 12px;
}

.dashboard .accu-social-income-container{
    height: 320px;
    /* width: 60rem; */
}

.dashboard .table .task,
.dashboard .table .worker{
    cursor: pointer;
    text-decoration: underline;
    color: var(--bs-primary);
    font-weight: bold;
}

.dashboard h5+hr{
    margin: 0.3rem 0 0.5rem;
}