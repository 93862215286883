#network-container #network-graph {
    position: absolute !important;
    width: calc(100% - 16rem) !important;
    height: 100% !important;
    top: 0 !important;
    right: 0 !important;
    z-index: 9 !important;
}


#tooltip .tooltip-arrow::before{
    border-bottom-color: var(--bs-black);
}

#tooltip .tooltip-inner{
    background-color: var(--bs-white);
    border: 1px black solid;
    border-radius: 5px;
    color: var(--bs-black);
    max-width: max-content;
}

#tooltip .tooltip-inner ul{
    text-align: left;
}

.circle{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 2px;
}

.purple-circle{
    background-color: var(--bs-primary);
}

.green-circle{
    background-color: var(--custom-green);
}

#network-container #agent-search-box{
    width: 250px;
    z-index: 10;
    position:relative;
    float:left;
}


#network-container #agent-detail-card-container.show {
    z-index: 10;
    position: relative;
    width: 35rem;
    float: right;
}

#network-container #agent-detail-card-container.hide {
    display: none;
}

#network-container #agent-detail-card-container .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--bs-primary);
    color: var(--bs-white);
}

#network-container #agent-detail-card-container .card-header #card-title {
    font-weight: normal;
    margin-top: .4em;
}

#network-container #agent-detail-card-container .card-header .card-desc {
    font-size: .8em;
    color: var(--bs-gray-500);
}

#network-container #agent-detail-card-container > .card > .card-body {
    max-height: 80vh;
    overflow-y: scroll;
    /* background-color: var(--bs-gray-100); */
    /* background-color: #f1ecf9; */

    /* background-color: #eeeaf5; */
    /* color: white; */
}

#network-container #agent-detail-card-container #card-close-icon:hover {
    cursor: pointer;
    color: var(--bs-info);
}

#network-container #agent-detail-card-container .table,
.react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown .btn,
.react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-menu,
.react-bootstrap-table-pagination .react-bootstrap-table-pagination-list .react-bootstrap-table-page-btns-ul>li>* {
    font-size: 12px !important;
}

.react-bootstrap-table-pagination .react-bootstrap-table-pagination-list .react-bootstrap-table-page-btns-ul{
    float: right;
}

#network-container #agent-detail-card-container .table .task {
    font-weight: bold;
    text-decoration: underline;
    color: var(--bs-primary);
    cursor: pointer;
}

#network-container #agent-detail-card-container .card-body > *:not(:first-child) {
    margin-top: 2.7em;
}

#network-container #agent-detail-card-container .publisher-cards-container > .row:not(:first-child) {
    margin-top: 1em;
}