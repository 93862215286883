.card.worker-income-card {
    background-color: transparent;
    border-radius: 7px;
    /* border: none; */
}

.card.worker-income-card .worker-income-graph-container {
    width: 180px;
    height: 90px;
    float: right;
}

.card.worker-income-card .card-title {
    color: var(--bs-gray-600);
    font-size: 13px;
    margin-bottom: 0;
}

.card.worker-income-card .card-content {
    font-weight: normal;
}