.task-aspect-card {
    border-radius: 5px;
    border-left: .3rem solid var(--bs-primary) !important;
    margin-bottom: 1.25rem;
}

.task-aspect-card .row{
    align-items: center;
}

.task-aspect-card .col-auto{
    width: auto;
    max-width: 100%;
}

.task-aspect-card .card-title {
    font-size: .75rem;
    margin: 0;
    color: var(--bs-primary);
}

.task-aspect-card .card-content {
    font-size: 1.75rem;
}

.task-aspect-card .bi{
    font-size: 2rem;
    color: var(--bs-gray-500);
}