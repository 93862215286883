.dashboard-card-wrapper{
    border: 1px dashed var(--bs-primary);
    border-radius: 12px;
}

.dashboard-card-wrapper:not(:last-child){
    margin-bottom: 2rem;
}


.dashboard-card-wrapper .dashboard-card-container{
    padding: 0.7em 2em;
}

.dashboard-card-wrapper .dashboard-card-container .dashboard-card-title{
    margin-bottom: 0.2rem;
    font-size: .8em;
}

.dashboard-card-wrapper .dashboard-card-container .dashboard-card-content{
    font-size: 2.7rem;
    margin: 0;
}